export class Participant {
    name: any;
    id: any;
    isMicOn: any;
    rtcPeer: any = {writable: true};
    constructor(name: any, id: any, isMicOn: any) {
        this.name = name;
        this.id = id;
        this.isMicOn = isMicOn;
    }
}