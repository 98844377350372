import { memo } from "react";
import { useSelector } from "react-redux";

import { ReduxState } from "../../redux/store";
import { CloseIcon } from "../icons/CloseIcon";

import "./UiModals.scss";

export type ActionFn = (result?: any) => void;

export type ModalComponent = React.FunctionComponent<{
    close: ActionFn;
    submit?: ActionFn | any;
}>;

export type UiModal = {
    component: ModalComponent;
    close: ActionFn;
    submit?: ActionFn | any;
};

const ModalWindow = ({
                         modal,
                         inFocus,
                     }: {
    modal: UiModal;
    inFocus: boolean;
}) => {
    return (
        <div
            className={`dialog-content ${!inFocus ? 'dialog-content_shade' : ''}`}
            role='dialog'
            tabIndex={-1}
            onClick={(e: any) => e.currentTarget === e.target}
            onKeyDown={(value) => {
                if (value.key === "Escape") {
                    modal.close();
                }
            }}
            style={{ position: !inFocus ? 'fixed' : undefined }}
        >
            <modal.component close={modal.close} submit={modal.submit} />
        </div>
    );
};

const ModalsList = memo(({ modals }: { modals: UiModal[] }) => {
    return (
        <>
            {modals.map((modal, idx) => (
                <ModalWindow
                    key={idx}
                    inFocus={idx === modals.length - 1}
                    modal={modal}
                />
            ))}
        </>
    );
});

const NonEmptyModals = ({ modals }: { modals: UiModal[] }) => {
    return (
        <div className="modal-back">
            <ModalsList modals={modals} />
        </div>
    );
};

export const UiModals = () => {
    const { modals } = useSelector(({modals}: ReduxState) => modals);
    return modals?.length < 1 ? <></> : <NonEmptyModals modals={modals} />;
};

export const CloseIconModal = ({ size = 18 }) => <CloseIcon />