import { BaseRequest } from "./BaseRequest";
import { ICommandResult } from "./models/CommandResult";
import { CreateJwtLinkRequest } from "./models/CreateJwtLinkRequest";
import { RecordHistory } from "./models/RecordHistory";

export class RoomApiService extends BaseRequest {
    /**
     * Создать комнату
     * @param config
     * @returns
     */
    createRoom(
        config?: Object
    ): Promise<ICommandResult<string>> {
        return this.fetchAPI(
            `/api/Room`,
            Object.assign({method: "POST", body: JSON.stringify({name: "Комната", connectionType: 2})}, config)
        )
            .catch(BaseRequest.handleError);
    }
    /**
     * Прикласить участника
     * @param config
     * @returns
     */
    createJwtLink(
        request: CreateJwtLinkRequest,
        config?: Object
    ): Promise<ICommandResult<string>> {
        return this.fetchAPI(
            `/api/Room/create-jwt-link`,
            Object.assign({method: "POST", body: JSON.stringify(request)}, config)
        )
            .catch(BaseRequest.handleError);
    }

    /**
     * Получить список записей
     * @param config
     * @returns
     */
    getRecords(
        roomId: any,
        config?: Object
    ): Promise<ICommandResult<RecordHistory[]>> {
        return this.fetchAPI(
            `/api/Room/records`,
            Object.assign({method: "POST", body: JSON.stringify({roomId})}, config)
        )
            .catch(BaseRequest.handleError);
    }
}