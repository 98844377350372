import { ADD_MODAL, ModalActionTypes, REMOVE_MODAL } from "../actionTypes";
import { ModalsState } from "../types";

export const modalsInitState: ModalsState = {
    modals: []
}

export const ModalsReducer = (state = modalsInitState, action: ModalActionTypes): ModalsState => {
    switch (action.type) {
        case ADD_MODAL:
            return {
                ...state,
                modals: [...state.modals, action.modal]
            }

        case REMOVE_MODAL:
            return {
                ...state,
                modals: state.modals.filter(m => m !== action.modal)
            }

        default:
            return state;
    }
}