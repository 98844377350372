import React from "react";

export const DeleteIcon = React.memo(() => {
    return (
        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1607_2392)">
                <path d="M3.62143 0.483984C3.76607 0.185938 4.06339 0 4.3875 0H7.6125C7.93661 0 8.23393 0.185938 8.37857 0.483984L8.57143 0.875H11.1429C11.617 0.875 12 1.26602 12 1.75C12 2.23398 11.617 2.625 11.1429 2.625H0.857143C0.383036 2.625 0 2.23398 0 1.75C0 1.26602 0.383036 0.875 0.857143 0.875H3.42857L3.62143 0.483984ZM0.857143 3.5H11.1429V12.25C11.1429 13.2152 10.3741 14 9.42857 14H2.57143C1.62589 14 0.857143 13.2152 0.857143 12.25V3.5ZM3.42857 5.25C3.19286 5.25 3 5.44688 3 5.6875V11.8125C3 12.0531 3.19286 12.25 3.42857 12.25C3.66429 12.25 3.85714 12.0531 3.85714 11.8125V5.6875C3.85714 5.44688 3.66429 5.25 3.42857 5.25ZM6 5.25C5.76429 5.25 5.57143 5.44688 5.57143 5.6875V11.8125C5.57143 12.0531 5.76429 12.25 6 12.25C6.23571 12.25 6.42857 12.0531 6.42857 11.8125V5.6875C6.42857 5.44688 6.23571 5.25 6 5.25ZM8.57143 5.25C8.33571 5.25 8.14286 5.44688 8.14286 5.6875V11.8125C8.14286 12.0531 8.33571 12.25 8.57143 12.25C8.80714 12.25 9 12.0531 9 11.8125V5.6875C9 5.44688 8.80714 5.25 8.57143 5.25Z" fill="#3D4247" fillOpacity="0.6"/>
            </g>
            <defs>
                <clipPath id="clip0_1607_2392">
                    <rect width="12" height="14" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
});