import { FC, ReactNode } from "react";
import { Spin } from "../Spin/Spin";

import "./CustomButton.scss";

interface IProps {
    children: ReactNode;
    onClick?: (event?: any) => void;
    className?: string;
    style?: object;
    loading?: boolean;
    disabled?: boolean;
    outline?: boolean;
}

export const CustomButton: FC<IProps> = ({
                                             children,
                                             className = '',
                                             loading = false,
                                             onClick,
                                             style,
                                             disabled = false,
                                             outline = false
                                         }) => {
    let _className = "button__primary";

    if (outline) {
        _className = "button__outline-primary";
    }

    if (disabled || loading) {
        _className = "button__disabled";
        if (outline) {
            _className = "button__outline-primary-disabled";
        }
    }

    return (
        <div
            onClick={(event?: any) => !disabled && !loading && onClick && onClick(event)}
            className={"button " + _className + " " + className}
            style={style}
        >
            {loading &&
                <div>
                    <Spin small={true}/>
                </div>
            }
            {children}
        </div>
    );
};
