import React from "react";

export const CameraOffIcon = React.memo(() => {
    return (
        <svg width="31" height="26" viewBox="0 0 31 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.73352 16.9304L15.5319 3.13208H5.10315C3.24335 3.13208 1.73352 4.65192 1.73352 6.51166V16.9304Z" fill="white"/>
            <path d="M29.5002 4.63195C28.5603 4.01202 27.3804 3.89208 26.3306 4.32201L23.171 5.63181C23.061 5.22185 22.881 4.84189 22.631 4.51194L24.5808 2.56218C25.1707 1.97221 25.1707 1.03233 24.5808 0.442425C23.9908 -0.147475 23.0409 -0.147475 22.4611 0.442425L0.433727 22.4697C-0.146233 23.0597 -0.146233 23.9896 0.443737 24.5795C0.733657 24.8695 1.12367 25.0195 1.50362 25.0195C1.88357 25.0195 2.27352 24.8695 2.5635 24.5795L5.25314 21.8899H19.9213C21.4711 21.8899 22.791 20.83 23.171 19.3902L26.3306 20.7C26.7505 20.87 27.1905 20.96 27.6204 20.96C28.2804 20.96 28.9403 20.76 29.5002 20.3901C30.4401 19.7601 31 18.7103 31 17.5804V7.44158C31 6.31176 30.4401 5.2619 29.5002 4.63195ZM28.0004 17.5804C28.0004 17.7504 27.9103 17.8403 27.8304 17.8904C27.7604 17.9404 27.6304 17.9903 27.4804 17.9304L23.2909 16.2006V8.82147L27.4804 7.09161C27.6304 7.03161 27.7604 7.08166 27.8304 7.13165C27.9104 7.18164 28.0004 7.27166 28.0004 7.44159V17.5804Z" fill="white"/>
        </svg>

    )
});