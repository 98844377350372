import { UiModal } from "../components/Modals/UiModals";

export type ChatActionTypes = SetSessionId | SetParticipants | SetExistingParticipants | SetNewParticipant | SetPeer | SetNewMessagesCount;

export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_PARTICIPANTS = 'SET_PARTICIPANTS';
export const SET_EXISTING_PARTICIPANTS = 'SET_EXISTING_PARTICIPANTS';
export const SET_NEW_PARTICIPANT = 'SET_PARTICIPANT';
export const SET_PEER = 'SET_PEER';
export const SET_NEW_MESSAGES_COUNT = 'SET_NEW_MESSAGES_COUNT';

interface SetSessionId {
    type: typeof SET_SESSION_ID,
    sessionId: string
}

interface SetParticipants {
    type: typeof SET_PARTICIPANTS,
    participants: any
}

interface SetExistingParticipants {
    type: typeof SET_EXISTING_PARTICIPANTS,
    existingParticipants: any
}

interface SetNewParticipant {
    type: typeof SET_NEW_PARTICIPANT,
    newParticipant: any
}

interface SetPeer {
    type: typeof SET_PEER,
    webRtcPeer: any
}

interface SetNewMessagesCount {
    type: typeof SET_NEW_MESSAGES_COUNT,
    newMessagesCount: any
}

export type PageActionTypes = SetPage | SetParams;

export const SET_PAGE = 'SET_PAGE';
export const SET_PARAMS = 'SET_PARAMS';

interface SetPage {
    type: typeof SET_PAGE,
    currentPage: string,
    params?: any;
}

interface SetParams {
    type: typeof SET_PARAMS,
    params: any
}


export type ModalActionTypes =
    AddModal | RemoveModal;

export const ADD_MODAL = 'ADD_MODAL';
export const REMOVE_MODAL = 'REMOVE_MODAL';

export interface AddModal {
    type: typeof ADD_MODAL,
    modal: UiModal
}

export interface RemoveModal {
    type: typeof REMOVE_MODAL,
    modal: UiModal
}