import { useSelector } from "react-redux";

import { ReduxState } from "../../redux/store";

export const Error = () => {
    const {params} = useSelector(({page}: ReduxState) => page);

    return <div className="chat-container">
        <div style={{color: 'white', fontSize: 25, textAlign: 'center', paddingTop: 50}}>
            <div>Ошибка</div>
            <div style={{fontWeight: 500}}>{params?.text}</div>
        </div>
    </div>
}