import "./Spin.scss";

/**
 * Индикатор загрузки.
 */
export const Spin = ({
                         text = '',
                         invert = false,
                         white = false,
                         small = false
                     }: {
    text?: string;
    invert?: boolean;
    white?: boolean;
    small?: boolean;
}) => {
    return (
        <div className="tm-communication-spin-container">
            <div className={`tm-communication-spin-circle${invert ? ' tm-communication-spin-circle_invert' : ''}${white ? ' tm-communication-spin-circle_white' : ''}${small ? ' tm-communication-spin-circle_small' : ''}`} />
            {text}
        </div>
    );
};