import React, { useRef, useState } from "react";
import { ChatUser } from "../../api/models/ChatUser";
import { ROBOT_ID } from "../../api/models/consts";
import { requestsRepository } from "../../api/RequestsRepository";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import { SettingsIcon } from "../icons/SettingsIcon";

interface IProps {
    users: ChatUser[];
    isAdmin: boolean;
    userId: string | null;
    chatId: string | null;
    changeChatAccess: (userId: string) => void;
    changeIsAdminAccess: (userId: string, isAdmin: boolean) => void;
}
export const UserInformation = (props: IProps) => {
    const { users, isAdmin, userId, chatId, changeChatAccess, changeIsAdminAccess } = props;

    const [showUserSettings, setShowUserSettings] = useState<any>(null);
    const [showDeleteUser, setShowDeleteUser] = useState<any>(null);
    
    const ref = useRef<HTMLDivElement | null>(null);

    useDetectOutsideClick(ref, () => setShowUserSettings(false));
    
    const onDeleteClick = (userId: string, chatId: string | null) => {
        requestsRepository.ChatApiService.changeReadAccess(userId, chatId).then((res) => {
            if (res.success) {
                changeChatAccess(userId);
                setShowDeleteUser(null);  
            }
        })
    }

    const onMakeAdminClick = (userId: string, chatId: string | null, isAdmin: boolean) => {
        requestsRepository.ChatApiService.changeIsAdminAccess(userId, chatId, isAdmin).then((res) => {
            if (res.success) {
                changeIsAdminAccess(userId, isAdmin);
                setShowDeleteUser(null);
            }
        })
    }
    
    const onCancelDeleteClick = () => {
        setShowDeleteUser(null);
    }

    return <div className="chat-wrapper">
        <div className="chat-date" >Информация</div>
        {users.filter(x => x.userId !== ROBOT_ID && x.canRead).map((user: any, key: number) =>
            <div key={key} className="user-item"><div>{user.userName}{user.isAdmin && <span>, <span style={{color: '#9EA0A3'}}>Администратор</span></span>}</div>{isAdmin && userId !== user.userId && <SettingsIcon ref={ref} className="chat-settings" onClick={() => {
                setShowUserSettings(user.userId)
            }}>
                <div style={{display: showUserSettings === user.userId ? 'flex' : 'none'}}
                     className="message-box-settings-container">
                    <div className="message-box-settings-item" onClick={() => {
                        setShowUserSettings(null);
                        setShowDeleteUser(user.userId);
                    }}>Удалить участника</div>
                    <div className="message-box-settings-item" onClick={() => {
                        setShowUserSettings(null);
                        onMakeAdminClick(user.userId, chatId, !user.isAdmin);
                    }}>{user.isAdmin ? 'Удалить права администратора' : 'Сделать администратором'}</div>
                </div>
                <div style={{display: showDeleteUser === user.userId ? 'flex' : 'none'}}
                     className="delete-message">
                    <div className="delete-message__title">Удалить участника?</div>
                    <div className="delete-message-btns-container">
                        <div className="delete-btn" onClick={() => onDeleteClick(user.userId, chatId)}>Удалить</div>
                        <div className="cancel-btn" onClick={onCancelDeleteClick}>Отменить</div>
                    </div>
                </div>
            </SettingsIcon>}</div>)}
    </div>
}