import React from "react";

export const FileIcon = React.memo(() => {
    return (
        <svg style={{minWidth: 11}} width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1607_2390)">
                <path d="M10.7692 3.50027H7.17949V0L10.7692 3.50027ZM7.17949 4.3752H10.7692V12.6879C10.7692 13.413 10.1667 14.0005 9.42282 14.0005H1.34642C0.603006 14.0005 0 13.4124 0 12.6879V1.31267C0 0.58757 0.602482 0 1.34642 0H6.28205V3.50027C6.28205 3.98402 6.68283 4.3752 7.17949 4.3752ZM3.61751 8.99549L4.71141 7.92631V10.7181C4.71141 11.0803 5.01265 11.3741 5.38462 11.3741C5.75658 11.3741 6.05782 11.0787 6.05782 10.7181V7.92631L7.17687 8.99549C7.28322 9.12392 7.45453 9.18707 7.62847 9.18707C7.8024 9.18707 7.97267 9.12392 8.10417 8.99549C8.36716 8.73916 8.36716 8.3239 8.10417 8.06758L5.86031 5.87998C5.59732 5.62365 5.17139 5.62365 4.90839 5.87998L2.66454 8.06758C2.40154 8.3239 2.40154 8.73916 2.66454 8.99549C2.92754 9.25182 3.35347 9.24968 3.61699 8.99549H3.61751Z" fill="#D0727A"/>
            </g>
            <defs>
                <clipPath id="clip0_1607_2390">
                    <rect width="10.7692" height="14" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
});