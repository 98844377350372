import React from "react";

export const CameraLoginIcon = React.memo(() => {
    return (
        <svg width="40" height="34" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2448_395)">
                <path d="M33.95 7.69012C32.97 7.0401 31.75 6.9201 30.66 7.36011L27.18 8.80011C26.82 7.26013 25.44 6.11011 23.79 6.11011H8C6.07001 6.11011 4.5 7.68012 4.5 9.61011V22.3901C4.5 24.3201 6.07001 25.8901 8 25.8901H23.79C25.44 25.8901 26.82 24.7401 27.18 23.2001L30.66 24.6401C31.09 24.8201 31.55 24.9001 32 24.9001C32.68 24.9001 33.36 24.7001 33.95 24.3101C34.92 23.6601 35.5 22.5701 35.5 21.4001V10.6001C35.5 9.43011 34.92 8.34009 33.95 7.69012ZM32.5 21.4001C32.5 21.6401 32.36 21.7601 32.28 21.8201C32.19 21.8701 32.02 21.9501 31.81 21.8701L27.29 20.0001V12.0001L31.81 10.1301C32.02 10.0501 32.19 10.1301 32.28 10.1801C32.36 10.2401 32.5 10.3601 32.5 10.6001V21.4001Z" fill="white"/>
            </g>
            <defs>
                <filter id="filter0_d_2448_395" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2448_395"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2448_395" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
});