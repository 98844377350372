import { UiModal } from "../../components/Modals/UiModals";
import { ADD_MODAL, REMOVE_MODAL } from "../actionTypes";

export function addModal(modal: UiModal) {
    return {
        type: ADD_MODAL,
        modal
    };
}

export function removeModal(modal: UiModal) {
    return {
        type: REMOVE_MODAL,
        modal
    };
}