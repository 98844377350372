import { useEffect } from "react";

/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.node} ref
 * @param handler
 */
export const useDetectOutsideClick: any = (ref: any, handler: any) => {
    useEffect(() => {
        const listener = (event: any) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target) || event.target?.classList?.contains("outer-click-btn")) {
                return;
            }
            handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handler]);
};