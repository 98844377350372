import React from "react";

export const UnableMicIcon = React.memo(() => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.0595 11.0008V10.4208L11.5095 16.0008C11.6927 16.011 11.8763 16.011 12.0595 16.0008C13.3856 16.0008 14.6574 15.474 15.5951 14.5363C16.5327 13.5986 17.0595 12.3269 17.0595 11.0008Z" fill="white"/>
            <path d="M20.0596 11.0008C20.0596 10.7356 19.9542 10.4812 19.7667 10.2937C19.5791 10.1062 19.3248 10.0008 19.0596 10.0008C18.7944 10.0008 18.54 10.1062 18.3525 10.2937C18.1649 10.4812 18.0596 10.7356 18.0596 11.0008C18.0596 12.5921 17.4274 14.1182 16.3022 15.2434C15.177 16.3687 13.6509 17.0008 12.0596 17.0008C11.5845 17.0009 11.111 16.9438 10.6496 16.8308L9.05957 18.4108C9.70011 18.6724 10.3727 18.8473 11.0596 18.9308V22.0008H7.55957C7.29435 22.0008 7.04 22.1061 6.85246 22.2937C6.66493 22.4812 6.55957 22.7356 6.55957 23.0008C6.55957 23.266 6.66493 23.5204 6.85246 23.7079C7.04 23.8954 7.29435 24.0008 7.55957 24.0008H16.5596C16.8248 24.0008 17.0791 23.8954 17.2667 23.7079C17.4542 23.5204 17.5596 23.266 17.5596 23.0008C17.5596 22.7356 17.4542 22.4812 17.2667 22.2937C17.0791 22.1061 16.8248 22.0008 16.5596 22.0008H13.0596V18.9308C14.9916 18.6874 16.7685 17.7476 18.0572 16.2878C19.3458 14.8279 20.0578 12.9481 20.0596 11.0008Z" fill="white"/>
            <path d="M23.7695 0.290793C23.5822 0.104542 23.3287 0 23.0645 0C22.8004 0 22.5469 0.104542 22.3595 0.290793L17.0595 5.59079V5.00079C17.0595 3.67471 16.5328 2.40294 15.5951 1.46526C14.6574 0.527577 13.3856 0.000792742 12.0595 0.000792742C10.7335 0.000792742 9.46169 0.527577 8.52401 1.46526C7.58633 2.40294 7.05954 3.67471 7.05954 5.00079V11.0008C7.06193 12.2257 7.51384 13.407 8.32954 14.3208L7.61954 15.0308C6.61705 13.9278 6.06095 12.4913 6.05954 11.0008C6.05954 10.7356 5.95419 10.4812 5.76665 10.2937C5.57911 10.1061 5.32476 10.0008 5.05954 10.0008C4.79433 10.0008 4.53997 10.1061 4.35244 10.2937C4.1649 10.4812 4.05954 10.7356 4.05954 11.0008C4.06104 13.02 4.83369 14.9623 6.21954 16.4308L0.349543 22.2908C0.244862 22.3804 0.159841 22.4908 0.0998176 22.6148C0.0397944 22.7389 0.00606468 22.874 0.000745191 23.0117C-0.0045743 23.1495 0.0186312 23.2868 0.0689057 23.4151C0.11918 23.5434 0.195439 23.66 0.292893 23.7574C0.390348 23.8549 0.506895 23.9312 0.63522 23.9814C0.763546 24.0317 0.900878 24.0549 1.0386 24.0496C1.17632 24.0443 1.31145 24.0105 1.43551 23.9505C1.55958 23.8905 1.6699 23.8055 1.75954 23.7008L23.7595 1.70079C23.9471 1.51475 24.0535 1.26204 24.0553 0.997855C24.0572 0.733667 23.9545 0.479473 23.7695 0.290793Z" fill="white"/>
        </svg>
    )
});