import React, {useEffect, useRef, useState} from "react";
import {fabric} from "fabric";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";

import {BrushIcon} from "../icons/BrushIcon";
import {AddTextIcon} from "../icons/AddTextIcon";
import {AddImageIcon} from "../icons/AddImageIcon";
import {AddText} from "../Modals/AddText/AddText";
import {ActionFn, UiModal} from "../Modals/UiModals";
import {addModal, removeModal} from "../../redux/actions/Modals";
import { RemoveIcon } from "../icons/RemoveIcon";

import "./WhiteBoard.scss";

interface IProps {
    connection: any;
    isAdmin: boolean;
    resize?: boolean;
}

export const WhiteBoard = (props: IProps) => {
    const {connection, isAdmin, resize} = props;
    const [canvas, setCanvas] = useState<any>(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [isTextMode, setIsTextMode] = useState(false);
    const [isDeleteMode, setIsDeleteMode] = useState(false);
    const [textCounter, setTextCounter] = useState(0);
    const [maxFileSize, setMaxFileSize] = useState(1);
    const canvasEl = useRef(null);
    const canvasElParent = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (connection !== null && canvas !== null) {
            connection?.on("DrawPathResponse", (message: any) => {
                canvas?.add(new fabric.Path(message?.path, {
                    stroke: 'red',
                    strokeWidth: 5,
                    strokeLineCap: "round",
                    strokeLineJoin: "round",
                    strokeMiterLimit: 10,
                    fill: undefined,
                    selectable: false,
                    name: message.name
                }));
            });
            connection?.on("DrawTextResponse", (message: any) => {
                addText(message.text, message.name);
            });
            connection?.on("LoadImageResponse", (message: any) => {
                addImage(message.image);
            });
            connection?.on("DragObjectResponse", (message: any) => {
                const objects = canvas.getObjects();
                const object = objects.find((x: any) => x.name === message.name);
                if (object) {
                    object.top = +message.top;
                    object.left = +message.left;
                    object.dirty = true;
                    canvas.renderAll();
                }
            });
            connection?.on("ResizeObjectResponse", (message: any) => {
                const objects = canvas.getObjects();
                const object = objects.find((x: any) => x.name === message.name);
                if (object) {
                    object.width = +message.width;
                    object.dirty = true;
                    canvas.renderAll();
                }
            });
            connection?.on("RemoveObjectResponse", (message: any) => {
                const objects = canvas.getObjects();
                const object = objects.find((x: any) => x.name === message.name);
                if (object) {
                    removeObject(object);
                }
            });
            connection?.on("ScaleObjectResponse", (message: any) => {
                const objects = canvas.getObjects();
                const object = objects.find((x: any) => x.name === message.name);
                if (object) {
                    object.top = +message.top;
                    object.left = +message.left;
                    object.scaleX = +message.scaleX;
                    object.scaleY = +message.scaleY;
                    object.dirty = true;
                    canvas.renderAll();
                }
            });
            connection?.on("ChangeTextResponse", (message: any) => {
                changeText(message.name, message.text);
            });

            connection?.on("ZoomResponse", (message: any) => {
                zoomToPoint(+message.zoomValue, +message.offsetX, +message.offsetY);
            });

            connection?.on("MaxFileSizeResponse", (message: any) => {
                setMaxFileSize(message?.maxFileSize)
            });

            if (isAdmin) {
                let linesCount = 0;
                canvas.on('path:created', (event: any) => {
                    const name = `line-${linesCount}`;
                    sendMessageSignal("DrawPath", {path: event.path.path, name});
                    event.path.selectable = false;
                    event.path.name = name;
                    linesCount++;
                });
                canvas.on('mouse:dblclick', (event: any) => {
                    if (event.target?.name?.includes("text")) {
                        canvas.isDrawingMode = false;
                        setIsDrawing(false);
                        changeDeleteMode(false);
                        setIsTextMode(true);
                        const component = AddText({
                            text: event.target.text,
                            onSuccess: (text: any) => {
                                changeText(event.target.name, text);
                                sendMessageSignal("ChangeText", {text, name: event.target.name});
                                close();
                            }
                        });
                        const close: ActionFn = () => {setIsTextMode(false); dispatch(removeModal(modal))};
                        const modal: UiModal = {component, close};
                        dispatch(addModal(modal));
                    }
                });
                canvas.on('object:modified', (event: any) => {
                    if (event.action === "drag") {
                        sendMessageSignal("DragObject", {
                            name: event.target.name,
                            left: event.target.left.toString(),
                            top: event.target.top.toString()
                        });
                    }
                    if (["scale", "scaleX", "scaleY"].includes(event.action)) {
                        sendMessageSignal("ScaleObject", {
                            name: event.target.name,
                            left: event.target.left.toString(),
                            top: event.target.top.toString(),
                            scaleX: event.target.scaleX.toString(),
                            scaleY: event.target.scaleY.toString()
                        });
                    }
                    if (event.action === "resizing") {
                        sendMessageSignal("ResizeObject", {
                            name: event.target.name,
                            width: event.target.width.toString()
                        });
                    }
                });
                canvas.on('mouse:wheel', (opt: any) => {
                    var delta = opt.e.deltaY;
                    var zoom = canvas.getZoom();
                    zoom *= 0.999 ** delta;
                    if (zoom > 20) zoom = 20;
                    if (zoom < 0.01) zoom = 0.01;
                    zoomToPoint(zoom, opt.e.offsetX, opt.e.offsetY);
                    sendMessageSignal("Zoom", {
                        value: zoom.toString(),
                        offsetX: opt.e.offsetX.toString(),
                        offsetY: opt.e.offsetY.toString()
                    });

                    opt.e.preventDefault();
                    opt.e.stopPropagation();
                });
            }
        }
    }, [connection, canvas]);

    const setCurrentDimensions = (dimensionCanvas: any, resize = false) => {
        if (dimensionCanvas && canvasElParent) {
            let width = canvasElParent?.current?.clientWidth || 0;
            let height = canvasElParent?.current?.clientHeight || 0;
            if (canvasElParent?.current?.clientWidth && resize) {
                width = canvasElParent?.current?.clientWidth - (canvasElParent?.current?.clientWidth * 0.3);
            }
            if (height > 0) {
                dimensionCanvas.setHeight(height);
            }
            if (width > 0) {
                dimensionCanvas.setWidth(width);
            }
            dimensionCanvas.renderAll();
        }
    }

    useEffect(() => {
        const fabricCanvas = new fabric.Canvas(canvasEl.current);
        const resizeCanvas = () => {
            setCurrentDimensions(fabricCanvas);
        };
        setCurrentDimensions(fabricCanvas);

        window.addEventListener('resize', resizeCanvas, false);
        fabricCanvas.freeDrawingBrush.color = 'red';
        fabricCanvas.freeDrawingBrush.width = 5;
        setCanvas(fabricCanvas);

        return () => {
            fabricCanvas.dispose()
            window.removeEventListener('resize', resizeCanvas)
        }
    }, []);
    
    useEffect(() => {
        if (canvas) {
            setCurrentDimensions(canvas, resize);
        }
    }, [canvas, resize])
    

    const changeDrawingMode = () => {
        canvas.isDrawingMode = !isDrawing
        setIsDrawing(!isDrawing);
        setIsTextMode(false);
    }
    
    const changeDeleteMode = (mode: boolean) => {
        if (mode) {
            canvas.isDrawingMode = false
            setIsDrawing(false);
            setIsTextMode(false);
            canvas.getObjects().forEach((obj: any) => {
                obj.on('mousedown', (opt: any) => {
                    sendMessageSignal("RemoveObject", {
                        name: opt.target.name
                    });
                    removeObject(opt.target);
                });
            })
        } else {
            canvas.getObjects().forEach((obj: any) => {
                obj.off('mousedown');
            })
        }
        setIsDeleteMode(mode);
    }
    
    const removeObject = (obj: any) => {
        canvas.remove(obj);
    }

    const addText = (text: any, name: any) => {
        canvas.add(new fabric.Textbox(text, {
            fontSize: 26,
            fontFamily: 'Roboto',
            stroke: 'red',
            fill: 'red',
            top: 150,
            left: 150,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
            editable: false,
            width: text.length * 15,
            name
        }));
    }

    const addImage = (image: any) => {
        clear();
        fabric.Image.fromURL(image, (img: any) => {
            img.scaleToHeight(250);
            img.scaleToWidth(250);
            img.left = 100;
            img.top = 100;
            img.lockRotation = true;
            img.name = 'image';
            canvas.add(img);
        });
    }

    const zoomToPoint = (zoomValue: any, offsetX: any, offsetY: any) => {
        canvas.zoomToPoint({x: offsetX, y: offsetY}, zoomValue);
    }

    const clear = () => {
        canvas.clear();
    }

    const handleFileChange = (event: any) => {
        canvas.isDrawingMode = false;
        setIsDrawing(false);
        const file = event.target.files[0];
        const filesize = +((file?.size / 1024) / 1024).toFixed(4);
        if (filesize >= maxFileSize) {
            toast.error(`Максимальный размер загружаемого файла ${maxFileSize} мегабайт`);
            return;
        }
        if (!["image/png", "application/pdf", "image/jpeg"].includes(file.type)) {
            return;
        }
        const reader = new FileReader();
        if (file.type !== "application/pdf") {
            reader.addEventListener("load", readImageFile);
            reader.readAsDataURL(event.target.files?.[0]);
        } else {
            reader.addEventListener("load", readPdfFile);
            reader.readAsArrayBuffer(file);
        }
    }

    const readImageFile = (event: any) => {
        sendMessageSignal("LoadImage", {image: event.target.result});
        addImage(event.target.result);
    }

    const readPdfFile = (event: any) => {
        var typedarray = new Uint8Array(event.target.result);
        pdfjsLib.GlobalWorkerOptions.workerSrc = "pdfjs-dist/build/pdf.worker.entry";
        pdfjsLib?.getDocument(typedarray)?.promise?.then(pdf => {
            pdf.getPage(1).then(page => {
                var viewport = page.getViewport({scale: 1});
                var canvasEl = document.createElement("canvas")
                canvasEl.height = viewport.height;
                canvasEl.width = viewport.width;
                const context: any = {
                    canvasContext: canvasEl.getContext('2d'),
                    viewport: viewport
                }
                page.render(context).promise.then(() => {
                    var bg = canvasEl.toDataURL("image/png");
                    sendMessageSignal("LoadImage", {image: bg});
                    addImage(bg);
                });
            });
        });
    }

    const onAddText = () => {
        canvas.isDrawingMode = false;
        setIsDrawing(false);
        changeDeleteMode(false);
        setIsTextMode(true);
        const component = AddText({
            text: "",
            onSuccess: (text: any) => {
                const name = `text-${textCounter}`;
                addText(text, name);
                sendMessageSignal("DrawText", {text, name});
                setTextCounter(textCounter + 1);
                close();
            }
        });
        const close: ActionFn = () => {setIsTextMode(false); dispatch(removeModal(modal))};
        const modal: UiModal = {component, close};
        dispatch(addModal(modal));
    }

    const changeText = (name: string, text: string) => {
        const objects = canvas.getObjects();
        console.log(objects);
        const object = objects.find((x: any) => x.name === name);
        if (object) {
            object.text = text;
            object.dirty = true;
            canvas.renderAll();
        }
    }

    const sendMessageSignal = (methodName: any, request: any) => {
        if (connection !== null) {
            if (request !== null) {
                connection.invoke(methodName, request);
            } else {
                connection.invoke(methodName);
            }
        }
    }

    return <div ref={canvasElParent} style={{width: '100%', height: 'calc(100% - 250px)', marginTop: 100, position: 'relative', background: '#242424'}}>
        <canvas width="720" height="480" ref={canvasEl}/>
        {isAdmin && <div className="board-buttons-container">
            <div className={`main-chat-btns-container`}>
                <div className={`chat-btn ${!isDrawing ? 'chat-btn_off' : ''}`}
                     onClick={changeDrawingMode}><BrushIcon/></div>
                <div className={`chat-btn ${!isTextMode ? 'chat-btn_off' : ''}`}
                     onClick={onAddText}><AddTextIcon/></div>
                <div className={`chat-btn ${!isDeleteMode ? 'chat-btn_off' : ''}`}
                     onClick={() => changeDeleteMode(!isDeleteMode)}><RemoveIcon /></div>
                <label className={`chat-btn chat-btn_off`}>
                    <input style={{display: 'none'}} type="file" accept="image/png, application/pdf, image/jpeg"
                           onChange={handleFileChange}/>
                    <AddImageIcon/>
                </label>
            </div>
        </div>}
    </div>;
}