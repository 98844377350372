import React from "react";

export const AddImageIcon = React.memo(() => {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.125 4.125H28.875" stroke="white" strokeWidth="3" strokeLinecap="round"/>
            <path d="M6.875 4.125H26.125V24.6133C26.125 24.6638 26.125 24.689 26.1194 24.7127C26.1143 24.7338 26.1059 24.7538 26.0946 24.7723C26.0818 24.7932 26.064 24.811 26.0283 24.8467L22.0967 28.7783C22.061 28.814 22.0432 28.8318 22.0223 28.8446C22.0038 28.8559 21.9838 28.8643 21.9627 28.8694C21.939 28.875 21.9138 28.875 21.8633 28.875H11.275C9.73485 28.875 8.96478 28.875 8.37653 28.5752C7.85907 28.3117 7.43838 27.8909 7.17474 27.3735C6.875 26.7853 6.875 26.0151 6.875 24.475V4.125Z" stroke="white" strokeWidth="3" strokeLinecap="round"/>
            <path d="M19.25 28.875V22.2062C19.25 22.0924 19.3424 22 19.4562 22H26.125" stroke="white" strokeWidth="3" strokeLinecap="round"/>
            <path d="M16.5 17.875V9.625" stroke="white" strokeWidth="3" strokeLinecap="round"/>
            <path d="M12.375 13.75L16.3541 9.77086C16.4347 9.69031 16.5653 9.69031 16.6459 9.77086L20.625 13.75" stroke="white" strokeWidth="3" strokeLinecap="round"/>
        </svg>
    )
});