import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import './index.scss';

let root: any = null;
// @ts-ignore
window.renderTMCommunication = (containerId) => {
    root = ReactDOM.createRoot(
        document.getElementById(containerId) as HTMLElement
    );
    root.render(<App />);
};
// @ts-ignore
window.unmountTMCommunication = containerId => {
    if (root) {
        root.unmount();
    }
};
if (!document.getElementById('TMCommunication-container') && document.getElementById('communicationRoot')) {
    root = ReactDOM.createRoot(
        document.getElementById('communicationRoot') as HTMLElement
    );
    root.render(<App/>);
}
