import React from "react";

export const WhiteBoardIcon = React.memo(() => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <g clipPath="url(#clip0_2982_136)">
                <path d="M6.28339 11.5958C5.77095 11.5958 5.35395 12.0127 5.35395 12.5253V19.475C5.35395 19.9874 5.77089 20.4044 6.28339 20.4044C6.79589 20.4044 7.21283 19.9875 7.21283 19.475V12.5252C7.21283 12.0127 6.79589 11.5958 6.28339 11.5958Z" fill="white"/>
                <path d="M29.4524 4.37256H2.54763C1.14288 4.37256 0 5.51537 0 6.92019V25.0799C0 26.4846 1.14288 27.6275 2.54763 27.6275H29.4524C30.8571 27.6275 32 26.4846 32 25.0799V6.92019C32.0001 5.51537 30.8571 4.37256 29.4524 4.37256ZM9.11389 19.4749C9.11389 21.0356 7.84408 22.3054 6.28339 22.3054C4.7227 22.3054 3.45288 21.0357 3.45288 19.4749V12.5252C3.45288 10.9645 4.72263 9.69469 6.28339 9.69469C7.84414 9.69469 9.11389 10.9644 9.11389 12.5252V19.4749Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_2982_136">
                    <rect width="32" height="32" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
});