import { ChatActionTypes, SET_EXISTING_PARTICIPANTS,
    SET_NEW_MESSAGES_COUNT, SET_NEW_PARTICIPANT, SET_PARTICIPANTS, SET_PEER, SET_SESSION_ID } from "../actionTypes"
import { ChatState } from "../types"

export const chatInitState: ChatState = {
    sessionId: null,
    participants: {},
    existingParticipatns: [],
    newParticipant: null,
    webRtcPeer: null,
    newMessagesCount: 0
}

export const ChatReducer = (state = chatInitState, action: ChatActionTypes): ChatState => {
    switch (action.type) {
        case SET_SESSION_ID:
            return {
                ...state,
                sessionId: action.sessionId
            }
        case SET_PARTICIPANTS:
            return {
                ...state,
                participants: action.participants
            }
        case SET_EXISTING_PARTICIPANTS:
            return {
                ...state,
                existingParticipatns: action.existingParticipants
            }
        case SET_NEW_PARTICIPANT:
            return {
                ...state,
                newParticipant: action.newParticipant
            }
        case SET_PEER:
            return {
                ...state,
                webRtcPeer: action.webRtcPeer
            }
        case SET_NEW_MESSAGES_COUNT:
            return {
                ...state,
                newMessagesCount: action.newMessagesCount
            }
        default:
            return state;
    }
}