import React from "react";

export const ChatSettingsIcon = React.forwardRef(({children, onClick, className} : any, ref: any) => {
    return (
        <div ref={ref} className={className} onClick={onClick}>
            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.31155 17.3897C6.45471 17.3897 8.19284 15.6431 8.19284 13.5C8.19284 11.3569 6.45471 9.61035 4.31155 9.61035C2.16845 9.61035 0.421875 11.3569 0.421875 13.5C0.421875 15.6431 2.16845 17.3897 4.31155 17.3897Z" fill="white"/>
                <path d="M13.5 17.3897C15.6482 17.3897 17.3897 15.6482 17.3897 13.5C17.3897 11.3518 15.6482 9.61035 13.5 9.61035C11.3518 9.61035 9.61035 11.3518 9.61035 13.5C9.61035 15.6482 11.3518 17.3897 13.5 17.3897Z" fill="white"/>
                <path d="M22.6884 9.61035C20.5453 9.61035 18.8071 11.3569 18.8071 13.5C18.8071 15.6431 20.5453 17.3897 22.6884 17.3897C24.8315 17.3897 26.5781 15.6431 26.5781 13.5C26.5781 11.3569 24.8315 9.61035 22.6884 9.61035Z" fill="white"/>
            </svg>
            {children}
        </div>
    )
});