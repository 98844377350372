import { months } from "../api/models/consts";

export const formatDate = (date: Date, formatString: string) => {
    const dt = new Date(date);
    if (dt.getDate()) {
        return formatString
            .replace("dd", padZero(dt.getDate()))
            .replace("DD", dt.getDate().toString())
            .replace("MMMM", months[dt.getMonth()].toLowerCase())
            .replace("MMM", padZero(dt.getMonth() + 1))
            .replace("MM", (dt.getMonth() + 1).toString())            
            .replace("yyyy", String(dt.getFullYear()))
            .replace("HH", padZero(dt.getHours()))
            .replace("mm", padZero(dt.getMinutes()))
            .replace("ss", padZero(dt.getSeconds()));
    } else {
        return "";
    }
};

const padZero = (n: number): string => (n < 10 ? `0${n}` : String(n));

export const timeDiff = (curr: Date, prev: Date) => {
    const ms_Min = 60 * 1000;
    const ms_Hour = ms_Min * 60;
    const diff = curr?.getTime() - prev?.getTime();
    const seconds = Math.round(diff / 1000);
    const minutes = Math.round(diff / ms_Min);
    const hours = Math.round(diff / ms_Hour);
    return `${hours > 0 ? `${hours}:` : ''}${minutes.toString().substring(0, 2)}:${padZero(seconds).substring(0, 2)}`;
}