import { combineReducers, legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import { thunk } from 'redux-thunk'

import { ChatState, ModalsState, PageState } from "./types";
import { chatInitState, ChatReducer } from "./reducers/Chat";
import { pageInitState, PageReducer } from "./reducers/Page";
import { modalsInitState, ModalsReducer } from "./reducers/Modals";

export type ReduxState = {
    chat: ChatState,
    page: PageState,
    modals: ModalsState
}

export const initialState: ReduxState = {
    chat: chatInitState,
    page: pageInitState,
    modals: modalsInitState
};

export const reducers = {
    chat: ChatReducer,
    page: PageReducer,
    modals: ModalsReducer
};

export const store: any = createStore(combineReducers({
    ...reducers
}), initialState as any, compose(applyMiddleware(thunk)));
