import { BaseRequest } from "./BaseRequest";
import { Chat } from "./models/Chat";
import { ChatMessage } from "./models/ChatMessage";
import { ChatUser } from "./models/ChatUser";
import { ICommandResult } from "./models/CommandResult";

export class ChatApiService extends BaseRequest {
    /**
     * Получение списка пользователей чата
     * @param chatId
     * @param config
     * @returns
     */
    getUsers(
        chatId: string | null,
        config?: Object
    ): Promise<ICommandResult<ChatUser[]>> {
        return this.fetchAPI(
            `/api/chat/users?chatId=${chatId}`,
            Object.assign({method: "GET"}, config)
        )
            .catch(BaseRequest.handleError);
    }

    /**
     * Получение списка сообщений чата
     * @param chatId
     * @param config
     * @returns
     */
    getMessages(
        chatId: string | null,
        config?: Object
    ): Promise<ICommandResult<ChatMessage[]>> {
        return this.fetchAPI(
            `/api/chat/messages?chatId=${chatId}`,
            Object.assign({method: "GET"}, config)
        )
            .catch(BaseRequest.handleError);
    }

    /**
     * Получение списка чатов пользователя
     * @param userId
     * @param config
     * @returns
     */
    getChats(
        userId: string | null,
        sessionId: string | null,
        config?: Object
    ): Promise<ICommandResult<Chat[]>> {
        let req = "";
        if (userId !== null) {
            req += `userId=${userId}`;
        }
        if (sessionId !== null) {
            req += `${req.length > 0 ? '&' : ''}sessionId=${sessionId}`
        }
        return this.fetchAPI(
            `/api/Chat/list?${req}`,
            Object.assign({method: "GET"}, config)
        )
            .catch(BaseRequest.handleError);
    }
    
    /**
     * Получение списка сообщений чата
     * @param chatId
     * @param userId
     * @param config
     * @returns
     */
    getChatUnreadMessages(
        chatId: string | null,
        userId: string | null,
        config?: Object
    ): Promise<ICommandResult<ChatMessage[]>> {
        return this.fetchAPI(
            `/api/Chat/chat-unread-messages?chatId=${chatId}&userId=${userId}`,
            Object.assign({method: "GET"}, config)
        )
            .catch(BaseRequest.handleError);
    }

    /**
     * Изменить доступ к чату
     * @param userId
     * @param chatId
     * @param config
     * @returns
     */
    changeReadAccess(
        userId: string | null,
        chatId: string | null,
        config?: Object
    ): Promise<ICommandResult<null>> {
        return this.fetchAPI(
            `/api/chat/change-read-access`,
            Object.assign({method: "POST", body: JSON.stringify({userId, chatId, access: false})}, config)
        )
            .catch(BaseRequest.handleError);
    }

    /**
     * Изменить админский доступ к чату
     * @param userId
     * @param chatId
     * @param config
     * @returns
     */
    changeIsAdminAccess(
        userId: string | null,
        chatId: string | null,
        isAdmin: boolean,
        config?: Object
    ): Promise<ICommandResult<null>> {
        return this.fetchAPI(
            `/api/Chat/change-is-admin`,
            Object.assign({method: "POST", body: JSON.stringify({userId, chatId, isAdmin})}, config)
        )
            .catch(BaseRequest.handleError);
    }
}