import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider as ReduxProvider } from "react-redux";

import { store } from './redux/store';
import { Main } from './Main';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { UiModals } from './components/Modals/UiModals';

function App() {    
    return <ReduxProvider store={store}>
            <UiModals />
            <BrowserRouter>
                <Routes>
                    <Route
                        path="*"
                        element={<Main />}
                    />
                </Routes>
            </BrowserRouter>
            <ToastContainer/>
        </ReduxProvider>;
}

export default App;
