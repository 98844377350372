import { useSearchParams } from "react-router-dom";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Messenger } from "../../components/Messenger/Messenger";
import { ReduxState } from "../../redux/store";
import { env } from "../../env";

import "./Chat.scss";

export const Chat = () => {
    const {params} = useSelector(({page}: ReduxState) => page);
    const [searchParams] = useSearchParams();
    
    const [userId] = useState<string | null>(params?.userId ?? searchParams.get('userId'));
    const [roomId] = useState<string | null>(params?.externalRoomId ?? searchParams.get('roomId'));
    const [sessionId] = useState<string | null>(params?.sessionId ?? searchParams.get('sessionId'));
    const [serviceType] = useState<string | null>(params?.serviceType ?? env().REACT_APP_SERVICE_TYPE);
    
    return <div className="chat-container">
        <Messenger visible={true} fullMode={true} userId={userId} externalRoomId={roomId} link={params?.link} showChatsList={params?.showChats} authSessionId={sessionId} serviceType={serviceType}/>
    </div>
}
