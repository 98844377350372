import { routes } from "../../routes";
import { PageActionTypes, SET_PAGE, SET_PARAMS } from "../actionTypes";
import { PageState } from "../types";

export const pageInitState: PageState = {
    currentPage: routes.videoChat,
    params: null
}

export const PageReducer = (state = pageInitState, action: PageActionTypes): PageState => {
    switch (action.type) {
        case SET_PAGE:
            return {
                ...state,
                currentPage: action.currentPage,
                params: action.params
            }
        case SET_PARAMS:
            return {
                ...state,
                params: action.params
            }    
        default:
            return state;
    }
}