import { FC, useState } from "react";
import { CustomButton } from "../../CustomButton/CustomButton";
import { Input } from "../../Input/Input";
import {CloseIconModal, ModalComponent } from "../UiModals";

interface IProps {
    onSuccess: (data: any) => void;
    text: string;
}

interface IPropsActions extends IProps {
    close: () => void;
}

const Modal: FC<IPropsActions> = ({
                                      close,
                                      onSuccess,
                                      text
                                  }) => {
    const [textValue, setTextValue] = useState(text);

    return (
        <div className="modal-content" style={{ width: 668 }}>
            <div className="modal-title">
                <div></div>
                <div className="modal-title__close" onClick={close}><CloseIconModal /></div>
            </div>
            <div className="modal-body">
                <div className="modal-content mb-40">
                    <Input
                            value={textValue}
                            onChange={setTextValue}
                            placeholder={"Введите текст"}
                            onKeyPress={(event: any) => {
                                if (event.charCode === 13 && textValue?.trim()?.length !== 0) {
                                    onSuccess(textValue);
                                }
                            }}
                        />
                </div>
                <div className="modal-footer">
                    <CustomButton
                        onClick={() => onSuccess(textValue)}
                        disabled={textValue?.trim()?.length === 0}
                    >
                        Сохранить
                    </CustomButton>
                    <CustomButton
                        outline
                        onClick={close}
                    >
                        Отменить
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export const AddText = (props: IProps): ModalComponent => {
    return ({ close }) => {
        return <Modal close={close} {...props} />;
    };
};

