import React from "react";

export const PhoneIcon = React.memo(() => {
    return (
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2448_18)">
                <path d="M14.7473 23.5142C15.2037 23.3994 15.5562 23.0461 15.6762 22.5918L16.2813 20.2883C16.4167 19.7726 16.8472 19.3946 17.3766 19.3342C18.2919 19.2299 19.7943 19.0911 21.2325 19.1038C22.6707 19.1166 24.1705 19.2819 25.0838 19.4024C25.6121 19.4721 26.0358 19.8577 26.162 20.3757L26.7264 22.6895C26.8376 23.1466 27.1887 23.5068 27.643 23.6296L33.3783 25.1837C33.9335 25.3336 34.5168 25.1026 34.8195 24.6131C35.1143 24.1384 35.3916 23.4146 35.4001 22.3688C35.429 19.0965 33.5704 15.451 21.2644 15.3422C8.9584 15.2333 7.03559 18.8439 7.00664 22.1177C6.99737 23.1656 7.26317 23.8942 7.54955 24.3754C7.84431 24.8681 8.41778 25.1093 8.97412 24.9693C10.3625 24.6188 13.3582 23.8639 14.7473 23.5142Z" fill="black"/>
                <path d="M14.7473 23.5142C15.2037 23.3994 15.5562 23.0461 15.6762 22.5918L16.2813 20.2883C16.4167 19.7726 16.8472 19.3946 17.3766 19.3342C18.2919 19.2299 19.7943 19.0911 21.2325 19.1038C22.6707 19.1166 24.1705 19.2819 25.0838 19.4024C25.6121 19.4721 26.0358 19.8577 26.162 20.3757L26.7264 22.6895C26.8376 23.1466 27.1887 23.5068 27.643 23.6296L33.3783 25.1837C33.9335 25.3336 34.5168 25.1026 34.8195 24.6131C35.1143 24.1384 35.3916 23.4146 35.4001 22.3688C35.429 19.0965 33.5704 15.451 21.2644 15.3422C8.9584 15.2333 7.03559 18.8439 7.00664 22.1177C6.99737 23.1656 7.26317 23.8942 7.54955 24.3754C7.84431 24.8681 8.41778 25.1093 8.97412 24.9693C10.3625 24.6188 13.3582 23.8639 14.7473 23.5142Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_2448_18">
                    <rect width="30" height="30" fill="white" transform="translate(42.4248 21.4) rotate(135.507)"/>
                </clipPath>
            </defs>
        </svg>
    )
});