import { useEffect, useLayoutEffect, useState } from "react";
import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { env } from "../../env";
import { ReduxState } from "../../redux/store";
import { setSessionId, setWebRtcPeer } from "../../redux/actions/Chat";
import { PlayIcon } from "../../components/icons/PlayIcon";
import { StopIcon } from "../../components/icons/StopIcon";
import { Spin } from "../../components/Spin/Spin";
import { setParams } from "../../redux/actions/Page";

import "./Player.scss";

const kurentoUtils: any = require('./../../lib/kurento-utils');

export const Player = () => {
    const dispatch = useDispatch();
    const [connection, setConnection] = useState<null | HubConnection>(null);
    const [spinner, setSpinner] = useState(false);
    const {sessionId, webRtcPeer} = useSelector(({chat}: ReduxState) => chat);
    const {params} = useSelector(({page}: ReduxState) => page);
    const [searchParams] = useSearchParams();
    const [recorder, setRecorder] = useState<any>(null);
    const [recording, setRecording] = useState(false);
    const [videoDuration , setVideoDuration] = useState(0);
    const [position, setPosition] = useState<any>(-1);
    const [repeatInterval, setRepeatInterval] = useState<any>(null);
    const [canResumeOnChangePosition, setCanResumeOnChangePosition] = useState(true);
    useEffect(() => {
        const connect = new HubConnectionBuilder()
            .withUrl(env().REACT_APP_CALL_HUB_URL ?? "")
            .withAutomaticReconnect()
            .build();

        setConnection(connect);
    }, []);
    
    useEffect(() => {
        if (searchParams?.get('fileName')) {
            dispatch(setParams(
                {
                    fileName: searchParams?.get('fileName')
                }
            ));
        }
    }, [searchParams])

    useLayoutEffect(() => {
        return () => {
            if (connection) {
                connection?.stop().then(() => {
                    setConnection(null);
                });
            }
        }
    }, [connection]);

    useEffect(() => {
        if (connection !== null) {
            connection.start().then(() => {
            });
        }
    }, [connection]);
    useEffect(() => {
        if (connection !== null) {
            connection.on("IceCandidate", (message) => {
                webRtcPeer.addIceCandidate(message.candidate, function (error: any) {
                    if (error)
                        return console.error('Error adding candidate: ' + error);
                });
            });

            connection.on("PlayerStop", (message) => {
                //@ts-ignore
                document.getElementById("stop-video").click();
            });

            connection.on("PlayerOffer", (message) => {
                dispatch(setSessionId(message.id));

                webRtcPeer.processAnswer(message.sdpAnswer, function (error: any) {
                    if (error)
                        return console.error(error);
                });
            });
            
            connection.on("VideoInfo", (message) => {
                setVideoDuration(message.duration);
            });

            connection.on("Position", (message) => {
                setPosition(message.position);
            });
        }
    }, [connection, webRtcPeer]);
    
    useEffect(() => {
        if (videoDuration !== 0 && repeatInterval === null) {
            const interval = setInterval(() => {
                connection?.invoke("GetVideoPosition");
            }, 100);
            setRepeatInterval(interval);
        }
    }, [repeatInterval, videoDuration])

    const start = () => {
        setSpinner(true);

        var userMediaConstraints = {
            audio: true,
            video: {mandatory: {
            maxWidth: 1920,
                maxFrameRate: 48,
                minFrameRate: 24
        }}
        }
        const video = document.getElementById('video');
        //@ts-ignore
        video.onloadstart = (args: any) => {
            //@ts-ignore
            let recorder = new MediaRecorder(video.captureStream());
            setRecorder(recorder);

            recorder.ondataavailable = (event) => {
                const data: any = new File([event.data], `${params?.fileName}.webm`, {type: 'video/webm'});
                const dt = new DataTransfer();
                dt.items.add(data);
                const body = new FormData();
                body.append("formFile", dt.files[0]);

                fetch(`${env().REACT_APP_BACKEND_URL}/api/Kurento/upload-file`, {
                    method: 'POST',
                    headers: {
                        contentType: 'text/plain; charset=utf-8'
                    },
                    body
                })

            };
            recorder.start();
            setRecording(true);
            setSpinner(false);
        }

        var options = {
            remoteVideo: video,
            mediaConstraints: userMediaConstraints,
            onicecandidate: onIceCandidate
        }

        const webRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options,
            function (error: any) {
                if (error)
                    return console.error(error);
                webRtcPeer.generateOffer(onOffer);
            });
        dispatch(setWebRtcPeer(webRtcPeer));
    }

    const onIceCandidate = (candidate: any) => {
        if (connection?.state === HubConnectionState.Connected) {
            connection?.invoke("OnIceCandidate", {sessionId, candidate});
        }
    }

    const onOffer = (error: any, offerSdp: any) => {
        if (error)
            return console.error('Error generating the offer');
        connection?.invoke("PlayVideo", {sdpOffer: offerSdp, fileName: params?.fileName});
    }

    // const stop = (stopPlayer: any = true) => {
    //     setRecording(false);
    //     if (recorder.state === "recording") {
    //         recorder.stop();
    //     }
    //     if (webRtcPeer) {
    //         webRtcPeer.dispose();
    //         setWebRtcPeer(null);
    //
    //         connection?.invoke("StopVideo", {stopPlayer});
    //     }
    //     setSpinner(false);
    // }
    
    const pause = (resume: boolean) => {
        setCanResumeOnChangePosition(resume);
        setRecording(false);
        connection?.invoke("PauseVideo");
        clearInterval(repeatInterval);
        if (recorder.state === "recording") {
            recorder.stop();
        }
    }
    
    const resume = () => {
        setRecording(true);
        connection?.invoke("ResumeVideo");
        const interval = setInterval(() => {
            connection?.invoke("GetVideoPosition");
        }, 100);
        setRepeatInterval(interval);
    }
    const setVideoPosition = (pos: any) => {
        connection?.invoke("SetVideoPosition", {position: +pos});
    }

    return <div className="player-wrapper">
        <div className="player-container">
            {spinner && <div className="player-loader-container"><Spin/></div>}
            <video style={{width: '100%', height: '80vh'}} id="video" autoPlay></video>
        </div>
        <div className="player-btns-container">
            {!recording && <div id="start-btn" className="chat-btn chat-btn__settings" onClick={() => recorder ? resume() : start()}><PlayIcon/></div>}
            {recording && <div className="chat-btn chat-btn__leave" id="stop-video" onClick={() => pause(false)}><StopIcon/></div>}
        </div>
        <input type="range" min="0" max={videoDuration} value={position} onMouseUp={() => {setVideoPosition(position); canResumeOnChangePosition && resume();}} onMouseDown={() => {canResumeOnChangePosition && pause(true)}} onChange={(event) =>{setPosition(event.target.value);}} />
    </div>;
}