import {
    SET_EXISTING_PARTICIPANTS,
    SET_NEW_MESSAGES_COUNT, SET_NEW_PARTICIPANT, SET_PARTICIPANTS, SET_PEER, SET_SESSION_ID
} from "../actionTypes";

export const setSessionId = (sessionId: any) => {
    return {
        type: SET_SESSION_ID,
        sessionId
    }
}

export const setParticipants = (participants: any) => {
    return {
        type: SET_PARTICIPANTS,
        participants
    }
}

export const setExistingParticipants = (existingParticipants: any[]) => {
    return {
        type: SET_EXISTING_PARTICIPANTS,
        existingParticipants
    }
}

export const setNewParticipant = (newParticipant: any) => {
    return {
        type: SET_NEW_PARTICIPANT,
        newParticipant
    }
}

export const setWebRtcPeer = (webRtcPeer: any) => {
    return {
        type: SET_PEER,
        webRtcPeer
    }
}

export const setNewMessagesCount = (newMessagesCount: any) => {
    return {
        type: SET_NEW_MESSAGES_COUNT,
        newMessagesCount
    }
}