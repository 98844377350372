import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import { ReduxState } from "./redux/store";
import { VideoChat } from "./pages/VideoChat/VideoChat";
import { routes } from "./routes";
import { Chat } from "./pages/Chat/Chat";
import { Error } from "./pages/Error/Error";
import { Player } from "./pages/Player/Player";
import { setPage } from "./redux/actions/Page";

export const Main = () => {
    const {currentPage} = useSelector(({page}: ReduxState) => page);
    const location = useLocation();
    const [params] = useSearchParams();
    const dispatch = useDispatch();

    const listener = useCallback(
        (event: any) => {
            if (event.data?.isActualCommunicationData && event.data?.data) {
                const data = event.data.data;
                if ((data.userId || data.sessionId) && data.route === routes.messenger) {
                    dispatch(setPage(routes.messenger, {userId: data.userId, sessionId: data.sessionId, externalRoomId: data.externalRoomId, 
                        link: event.data.link, showChats: data.showChats, serviceType: data.serviceType}))
                }
            }
        },
        []
    );
    
    useEffect(() => {
        if (location.pathname.includes(routes.messenger)) {
            dispatch(setPage(routes.messenger, {showChats: true}));
        }
        if (location.pathname.includes(routes.error)) {
            dispatch(setPage(routes.error, {text: params.get('text')}));
        }
        if (location.search.includes(routes.player)) {
            dispatch(setPage(routes.player, {fileName: params.get('fileName')}));
        }
    }, [location]);

    useEffect(() => {
        try {
            if (window.addEventListener) {
                window.postMessage("communicationLoad", "*");
                window.addEventListener("message", listener, false);
            }

            return () => window.removeEventListener("message", listener);
        } catch (err) {
            console.error("Error Listener: ", err);
        }
    }, [listener]);
    
    switch (currentPage) {
        case routes.messenger:
            return <Chat />;
        case routes.player:
            return <Player />;
        case routes.error:
            return <Error />;
        default:
            return <VideoChat />
    }
}