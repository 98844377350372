import { AttachmentResult } from "./models/AttachmentResult";
import { BaseRequest } from "./BaseRequest";
import { ICommandResult } from "./models/CommandResult";

export class AttachmentApiService extends BaseRequest {
    /**
     * Отправить файл в xds
     * @param file
     * @param config
     * @returns
     */
    addAttachment(
        file: any,
        config?: Object
    ): Promise<ICommandResult<AttachmentResult>> {
        const body = new FormData();
        body.append("formFile", file);
        return this.fetchAPI(
            `/api/Attachment`,
            Object.assign({method: "POST", body: body, ...config})
        )
            .catch(BaseRequest.handleError);
    }
}