import React from "react";

export const SettingsIcon = React.forwardRef(({children, onClick, className, color = '#D9D9D9'} : any, ref: any) => {
    return (
        <div ref={ref}>
            <svg className={className} onClick={onClick} width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="2" cy="2" r="2" fill={color}/>
                <circle cx="2" cy="8" r="2" fill={color}/>
                <circle cx="2" cy="14" r="2" fill={color}/>
            </svg>
            {children}
        </div>
    )
});