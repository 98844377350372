import { toast } from "react-toastify";
import { env } from "../env";

export class BaseRequest {
    constructor() {
        BaseRequest.headers.set("accept", "application/json");
        BaseRequest.headers.set("Content-Type", "application/json");
    }

    static headers = new Headers();
    private static baseUrl = env().REACT_APP_BACKEND_URL;
    
    static handleError = (error: any): Promise<any> => {
        toast.error(error.message ?? "Произошла ошибка при подключении к серверу.");

        return Promise.reject(error.message || error);
    };

    async fetchAPI(url: string, config: Record<string, any>, needErrorModal = true): Promise<any> {
        const request = await fetch(BaseRequest.baseUrl + url, {
            headers: BaseRequest.headers,
            ...config,
        });

        const response = await request.json();

        if (!response.success) {
            toast.error(response.message ?? "Произошла ошибка при подключении к серверу.");
        }

        return response;
    }
}