import React from "react";

export const SendIcon = React.memo(({color = "#DADADA"}: any) => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2467_3)">
                <path d="M18.4844 0.207816C18.8592 0.467561 19.0559 0.91655 18.9854 1.36554L16.6101 16.8018C16.5545 17.1618 16.3355 17.4772 16.0163 17.6553C15.6971 17.8334 15.3149 17.8557 14.9771 17.7147L10.2897 15.7666L8.80141 18.531C8.60842 18.8909 8.19646 19.0727 7.79934 18.9726C7.40222 18.8724 7.12387 18.5162 7.12387 18.108V14.5458C7.12387 14.3491 7.19067 14.1561 7.31315 14.0003L13.448 6.11146C13.5408 5.848 13.2068 5.58083 12.9656 5.79976L6.32221 11.8073L5.13457 12.8797C4.79313 13.1877 4.30694 13.273 3.88013 13.0949L0.725462 11.7813C0.309789 11.6069 0.0277245 11.2136 0.00174485 10.7609C-0.0242348 10.3082 0.20587 9.88147 0.599276 9.65513L17.2262 0.155867C17.6233 -0.0704825 18.1132 -0.0482186 18.4881 0.207816H18.4844Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_2467_3">
                    <rect width="19" height="19" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
});