import { SET_PAGE, SET_PARAMS } from "../actionTypes"

export const setPage = (currentPage: any, params?: any) => {
    return {
        type: SET_PAGE,
        currentPage,
        params
    }
}

export const setParams = (params: any) => {
    return {
        type: SET_PARAMS,
        params
    }
}