import "./Input.scss";

interface IProps {
    title?: string;
    description?: string;
    required?: boolean;
    error?: any;
    style?: any;
    type?: string;
    disabled?: boolean;

    placeholder?: string;
    value: string;
    onKeyPress?: (value: unknown) => void;
    onChange: (value: any) => void;
}

export const Input = (props: IProps) => {
    return (
        <div style={props.style} className="form-input__container">
            {props.title && (
                <span
                    className={`form-input__title ${
                        props.error ? "form-input__title_error" : ""
                    }`}
                >
          {props.title} {props.required && <div className="form-input__required">*</div>}
        </span>
            )}
            <input
                className={`form-input ${props.error ? "form-input_error" : ""}`}
                placeholder={props.placeholder}
                value={props.value ?? ""}
                disabled={props.disabled ?? false}
                type={props.type ? props.type : "text"}
                onChange={e => props.onChange(e.currentTarget.value)}
                autoComplete="new-password"
                onKeyPress={event => {
                    if (props.onKeyPress !== undefined) {
                        props.onKeyPress(event);
                    }
                }}
            />
            {props.description && (
                <span className="form-input__description">{props.description}</span>
            )}
        </div>
    );
};