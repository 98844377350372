import React from "react";

export const RecievedIcon = React.memo(() => {
    return (
        <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.67175 5.56524L5.99093 4.29154L4.5 2.81612C4.13607 2.46462 3.54521 2.46462 3.18128 2.81612C2.81735 3.16763 2.81735 3.73832 3.18128 4.08982L4.67175 5.56524Z" fill="#147DC1"/>
            <path d="M16.7267 0.263626H16.7295C16.3655 -0.0878753 15.7747 -0.0878753 15.4108 0.263626L8.61577 6.82396L8.60253 6.81117L7.28198 8.08663L7.95459 8.73626C8.31851 9.08777 8.90938 9.08777 9.27331 8.73626L16.7267 1.53732C17.0907 1.18582 17.0907 0.615127 16.7267 0.263626Z" fill="#147DC1"/>
            <path d="M12.7716 1.53743C13.1355 1.18593 13.1355 0.615238 12.7716 0.263736H12.7743C12.5926 0.0877651 12.3538 0 12.115 0C11.8761 0 11.6373 0.0877651 11.4556 0.263736L6.63733 4.91529L5.31769 6.18942L4.66016 6.82407L1.59167 3.86299C1.40993 3.68746 1.17112 3.59925 0.932306 3.59925C0.693493 3.59925 0.45468 3.68702 0.272945 3.86299C-0.0909817 4.21449 -0.0909817 4.78518 0.272945 5.13668L3.99943 8.73593C4.18162 8.91146 4.41998 8.99967 4.65879 8.99967C4.8976 8.99967 5.13642 8.91191 5.31815 8.73593L6.63642 7.46268L7.95651 6.18766L12.7716 1.53699V1.53743Z" fill="#147DC1"/>
        </svg>
    )
});