export const months = [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря"
];

export const SCREEN_ID = "22222222-2222-2222-2222-222222222222";
export const ROBOT_ID = "11111111-1111-1111-1111-111111111111";