import React from "react";

export const AddTextIcon = React.memo(() => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.375 23.25C12.375 23.8713 12.8787 24.375 13.5 24.375C14.1213 24.375 14.625 23.8713 14.625 23.25H12.375ZM16.875 15.6375C16.875 16.2588 17.3787 16.7625 18 16.7625C18.6213 16.7625 19.125 16.2588 19.125 15.6375H16.875ZM7.87501 15.6375C7.87501 16.2588 8.37869 16.7625 9.00001 16.7625C9.62132 16.7625 10.125 16.2588 10.125 15.6375H7.87501ZM10.5 22.125C9.87869 22.125 9.37501 22.6287 9.37501 23.25C9.37501 23.8713 9.87869 24.375 10.5 24.375V22.125ZM16.5 24.375C17.1213 24.375 17.625 23.8713 17.625 23.25C17.625 22.6287 17.1213 22.125 16.5 22.125V24.375ZM11.8875 13.875H13.5V11.625H11.8875V13.875ZM13.5 13.875H15.1125V11.625H13.5V13.875ZM14.625 23.25V12.75H12.375V23.25H14.625ZM15.1125 13.875C15.7943 13.875 16.2011 13.8771 16.4948 13.9137C16.7609 13.9469 16.7747 13.9925 16.7492 13.9697L18.2436 12.2878C17.7918 11.8863 17.2664 11.7425 16.7733 11.681C16.3079 11.623 15.7358 11.625 15.1125 11.625V13.875ZM19.125 15.6375C19.125 15.0143 19.1271 14.4421 19.0691 13.9767C19.0076 13.4837 18.8637 12.9582 18.4622 12.5063L16.7804 14.0009C16.7576 13.9753 16.8032 13.9891 16.8363 14.2552C16.8729 14.549 16.875 14.9557 16.875 15.6375H19.125ZM16.7492 13.9697C16.7601 13.9795 16.7706 13.9899 16.7804 14.0009L18.4622 12.5063C18.3938 12.4293 18.3207 12.3563 18.2436 12.2878L16.7492 13.9697ZM11.8875 11.625C11.2642 11.625 10.6921 11.623 10.2267 11.681C9.73372 11.7425 9.20818 11.8863 8.75635 12.2878L10.2509 13.9697C10.2253 13.9925 10.2391 13.9469 10.5052 13.9137C10.799 13.8771 11.2057 13.875 11.8875 13.875V11.625ZM10.125 15.6375C10.125 14.9557 10.1271 14.549 10.1637 14.2552C10.1969 13.9891 10.2425 13.9753 10.2197 14.0009L8.5378 12.5063C8.13629 12.9582 7.99249 13.4837 7.931 13.9767C7.87295 14.4421 7.87501 15.0143 7.87501 15.6375H10.125ZM8.75635 12.2878C8.67926 12.3563 8.60629 12.4293 8.5378 12.5063L10.2197 14.0009C10.2295 13.9899 10.2399 13.9795 10.2509 13.9697L8.75635 12.2878ZM10.5 24.375H16.5V22.125H10.5V24.375Z" fill="white"/>
            <path d="M33 18C33 23.6568 33 26.4854 31.2426 28.2426C29.6479 29.8373 27.1714 29.985 22.5 29.9987M18 6H15C9.34314 6 6.51472 6 4.75735 7.75735C3 9.51472 3 12.3431 3 18C3 23.6568 3 26.4854 4.75735 28.2426C6.51472 30 9.34314 30 15 30H18M22.5 6.00139C27.1714 6.01506 29.6479 6.16272 31.2426 7.75735C32.2224 8.73712 32.6559 10.0498 32.8477 12" stroke="white" strokeWidth="3" strokeLinecap="round"/>
            <path d="M22.5 3V33" stroke="white" strokeWidth="3" strokeLinecap="round"/>
        </svg>
    )
});